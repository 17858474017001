import Image from "next/image";
import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Symptoms(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className="lg:max-w-[815px] ">
                <h2>Uterine Cancer Signs & Symptoms</h2>

                <span></span>
                <p>
                    It's important to speak to your health care provider as soon
                    as possible if you notice any of these uterine cancer
                    symptoms:
                </p>
                <ul>
                    <li>
                        Cramping or pain in the lower abdomen or pelvic area
                        below your belly.
                    </li>
                    <li>
                        Vaginal bleeding or spotting between periods before
                        menopause.
                    </li>
                    <li>
                        Vaginal bleeding or spotting postmenopause to any
                        degree.
                    </li>
                    <li>
                        Very frequent, heavy, or prolonged vaginal bleeding over
                        the age of 40.
                    </li>
                    <li>
                        White or clear vaginal discharge in postmenopausal
                        women.
                    </li>
                </ul>
                <p>
                    If you used hair relaxers and were later diagnosed with
                    uterine cancer, you may be eligible to pursue compensation
                    from a chemical hair straightening lawsuit.
                </p>
            </div>
            <div className="relative  lg:w-[327px] lg:h-[317px] w-[277px] h-[267px] mb-10 lg:mb-0 lg:min-w-[327px]">
                <Image
                    src="/hairstraightener/assets/images/hairStraightenerImg2.png"
                    alt="hairstraightener"
                    layout="fill"
                />
            </div>
        </div>
    );
}
